(function(wnd){
    function CookiePopup(params) {
        try {
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    CookiePopup.prototype = {
        cookieHandlerObj: undefined,
        container: undefined,
        overlay: undefined,
        closeBtn: undefined,
        form: undefined,
        selectors: {
            open_button: '[data-purpose="cookie-settings-button"]',
            container: '.cookie-popup-container',
            overlay: '.cookie-popup-overlay',
            save_button: '.cookie-save',
            save_all_button: '.cookie-save-all',
            close_button:'.cookie-close-btn'
        },
        options: {
            hideOnOverlay: true
        },
        groupTitle: undefined,
        currentGroup: '0',
        sendedForm: false,
        deletableCookies: [],
        init:function() {
            this.cookieHandlerObj = new CookieHandler();      
            this.setElements();  
            this.addDOMEvents();
            if (this.deletableCookies && Array.isArray(this.deletableCookies) && this.deletableCookies.length > 0) {
                this.cookieHandlerObj.deleteCookies(this.deletableCookies);
            }
        },
        setElements: function() {        
            this.overlay = this.overlay = $(this.getSelector('overlay'));
            this.container = $(this.getSelector('container'));
            
            this.groupTitle = this.container.find('.group-name');
            this.form = this.container.find('form');
        },
        addDOMEvents: function() {
            var _self = this;

            if (this.getOption('hideOnOverlay')) {
                this.overlay.click(function() {
                    _self.close();
                });
            }
            
            $(this.getSelector('open_button')).click(function(){
                _self.open();
            });

            $(this.getSelector('close_button')).click(function(){
                _self.close();
            });

            $(this.getSelector('save_button')).click(function(){
                _self.sendForm(false);
            });

            $(this.getSelector('save_all_button')).click(function(){
                _self.sendForm(true);
                $(this).addClass('closed');
            });

            if (this.closeBtn && this.closeBtn.length > 0) {
                this.closeBtn.click(function(){
                    _self.close();
                });
            }

            this.groupTitle.click(function(){
                _self.changeSelectedGroup(this);
            });
        },
        setParams: function(params) {
            if (params.selectors) this.setSelectors(params.selectors);
            if (params.deletableCookies) this.setDeletableCookies(params.deletableCookies);
        },
        setSelectors: function(selectors) {
            this.selectors = selectors;
        },
        setDeletableCookies: function(cookies) {
            this.deletableCookies = cookies;
        },
        changeSelectedGroup: function(row) {
            $('.cookie-group-'+this.currentGroup).removeClass('selected');
            $('[data-group-id="'+this.currentGroup+'"]').removeClass('selected');
            this.currentGroup = $(row).data('group-id');
            $('[data-group-id="'+this.currentGroup+'"]').addClass('selected');
            $('.cookie-group-'+this.currentGroup).addClass('selected');
        },
        open:function() {
            $('body').addClass('overflow-hidden opened-cookie-popup');
            $('html').addClass('overflow-hidden');
        },
        close: function() {
            $('body').removeClass('overflow-hidden opened-cookie-popup');
            $('html').removeClass('overflow-hidden');          
        },
        getSelector: function(selector) {
            if (typeof this.selectors[selector] === 'undefined') {            
                throw 'Selector is undefined: '+selector+'!';
            }
            return this.selectors[selector];
        },
        sendForm: function(all) {
            var _self = this;
            if (!this.sendedForm) {
                _self.sendedForm = true;
                var data = {};

                this.form.find('input[type="checkbox"]').each(function(){
                    data[$(this).attr('name')] = all || $(this).prop('checked') ? 1 : 0;
                });

                $.ajax({
                    url: this.form.attr('action'),
                    type: 'post',
                    dataType:'json',
                    data: data,
                    success: function(response) {
                        _self.cookieHandlerObj.handleCookieSave(response.groups);
                    },
                    complete: function(){
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 500);
                    }
                });
            }
        },
        getOption: function(key) {
            return typeof this.options[key] !== 'undefined' ? this.options[key] : null;
        }
    }

    wnd.CookiePopup = CookiePopup;
})(window);
